<template>
  <Header class="header">
    <div class="container h100">
      <div class="header__content row h100">
        <div class="header__top d-flex align-center justify-between response">
          <a class="header__logo" href="#"><img src="@/assets/images/logo.png" alt="logo"></a>
          <div class="header__btn">
            <button class="btn white-t" @click="changeToggleRegistration(true)">{{$t('landing_page.header.register')}}</button>
            <button class="btn white-t" @click="changeToggleLogin(true)">{{$t('landing_page.header.log_in')}}</button>
          </div>
        </div>
        <div class="header__title" v-if="mainBanner">
          {{mainBanner.title}}
          <small>{{mainBanner.sub_title}} </small>
          <div class="header__info col-md-6 row">
            {{mainBanner.description}}
          </div>
        </div>
        <div class="header__down col-md-12 row">
          <span>{{$t('global.scroll_down')}}</span>
        </div>
      </div>
    </div>
  </Header>
</template>
<script>
import {mapMutations} from 'vuex';

  export default {
    props:['mainBanner'],
    data(){
      return{
      }
    },
    methods:{
      ...mapMutations({
        changeToggleRegistration: 'general/changeToggleRegistration',
        changeToggleLogin: 'general/changeToggleLogin',
      })
    }
  }
</script>

<style lang="scss" scoped>
@import "src/assets/styles/styles";
  .header{
    padding: 15px;
    height: 90vh;
    min-height: 600px;
    background: url('~@/assets/images/header-bg.jpg') no-repeat;
    background-size: cover;
    position: relative;
    @media screen and (max-width: 1198px) {
      &:after{
        display: none;
      }
    }
    &:after{
      content: '';
      background: url('~@/assets/images/doctor.png') no-repeat;
      background-size: contain;
      background-position: bottom;
      position: absolute;
      right: 5%;
      bottom: 0;
      height: 85%;
      width: 650px;
      @media screen and (max-width: 1660px) {
        height: 80%;
        width: 520px;
      }
    }
    &__content{
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }
    &__top{
    }
    &__logo{
      @media screen and (max-width: 991px){
        margin-bottom: 25px;
      }
      img{
        width: 260px;
      }
    }
    &__btn{
      .btn{
        margin-left: 30px;
      }
    }
    &__title{
      font-size: 144px;
      font-weight: 900;
      color: $white;
      line-height: 1.2;
      margin: auto 0;
      font-family: 'Mont', Arial;
      @media screen and (max-width: 991px){
        font-size: 100px;
      }
      @media screen and (max-width: 768px){
        font-size: 70px;
      }
      small{
        font-size: 26px;
        font-weight: 400;
        display: block;
        font-family: 'Montserrat', Arial;
      }
    }
    &__info{
      margin-top: 30px;
      font-size: 16px;
      font-weight: 500;
      color: $white;
      font-family: 'Montserrat', Arial;
    }
    &__down{
      color: white;
      height: 130px;
      position: relative;
      &:before{
        content: '';
        background-image: url("~@/assets/images/arrow-down.png");
        background-repeat: no-repeat;
        background-position: bottom center;
        background-size: contain;
        position: absolute;
        height: 100%;
        width: 7px;
        left: 15px;
        bottom: 0;
      }
      span{
        display: inline-block;
        margin-top: 50px;
        transform: rotate(90deg);
        position: relative;
        right: 35px;
        bottom: 8px;
        font-family: 'Montserrat', Arial;
        letter-spacing: 0.22em;
        font-weight: 600;
        font-size: 10px;
        text-transform: uppercase;
      }
    }
  }
</style>
